
<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />

</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_kalp',
      headers: [
        [{
          label: 'Nama Kegiatan',
          dataKey: 'nama_kegiatan'
        }, {
          label: 'Nama Dosen Pembimbing',
          dataKey: 'data_master_dosen.full_name'
        }, {
          label: 'Frekuensi Kegiatan',
          dataKey: 'frekuensi_kegiatan',
        }, {
          label: 'Hasil Kegiatan',
          dataKey: 'hasil_kegiatan',
        }, {
          label: 'Bukti Kegiatan',
          dataKey: 'bukti_kegiatan',
        }]
      ],
      form: {
        inputs: [{
          label: 'Nama Kegiatan',
          name: 'nama_kegiatan',
          dataKey: 'nama_kegiatan',
          type: 'text',
          rules: 'required'
        }, {
          label: 'Nama Dosen',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'Frekuensi Kegiatan',
          name: 'frekuensi_kegiatan',
          dataKey: 'frekuensi_kegiatan',
          type: 'text',
          rules: 'required'
        }, {
          label: 'Hasil Kegiatan',
          name: 'hasil_kegiatan',
          dataKey: 'hasil_kegiatan',
          type: 'text',
          rules: 'required'
        }, {
          label: 'Bukti Kegiatan',
          name: 'bukti_kegiatan',
          dataKey: 'bukti_kegiatan',
          type: 'text',
          rules: 'required'
        }]
      },
    }
  },
}
</script>
